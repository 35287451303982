<template>
  <div>
    <Dialog title="二级章节"
            :visible="dialogVisible"
            :close-on-click-modal="false"
            :append-to-body="true"
            :width='1250'
            @cancel="handleClose"
            @submit="submit"
            :footer="null">
      <template #content>
        <div class="center_table">
          <div class="table_head">
            <div>
              章节
            </div>
            <div>
              <!-- 得分率 -->
              频率
            </div>
            <div>
              操作
            </div>
          </div>
        </div>
        <div class="table_line"
             v-for="(item,index) in list"
             :key="index">

          <div class="two">
            <div>
              {{item.chapter_desc}}
            </div>
            <div>
              <img src="@/static/zhishidian/xx.jpg"
                   v-for="(item,idx) in item.chapter_star"
                   :key="idx"
                   style="width:20rem;height:20rem" />
              <!-- {{item.rate}} -->
            </div>
            <div>
              <a-button type="primary"
                        @click="toPaper(item)">
                进入训练
              </a-button>
            </div>
            <!-- {{item.active}} -->
            <span class="icon"
                  :style="{'background':item.active?'#2196F3':'#D8D8D8'}"
                  v-if="item.child && item.child.length">
              <span>
                <plus-outlined @click="change(index,true)"
                               v-if="item.active == false " />
                <minus-outlined @click="change(index,false)"
                                v-else />
                <!-- <i @click="change(index,true)"
                   v-if="item.active == false "
                   class="el-icon-plus"></i> -->
                <!-- <i @click="change(index,false)"
                   v-else
                   class="el-icon-minus"></i> -->
              </span>
            </span>
          </div>
          <div v-if="item.active">
            <div class="three"
                 :style="{'border-bottom':idx == item.child.length -1 ?'':'1px solid #eaeaea'}"
                 v-for="(itm,idx) in item.child"
                 :key="itm.knowledge_id">
              <div>
                {{itm.chapter_desc}}
              </div>
              <div>
                {{itm.rate}}
              </div>
              <div>
                <img src="@/static/bi.png"
                     @click="toPaper(itm)"
                     style="width:40px;height:40px;margin-left:78px"
                     alt="">
              </div>
            </div>
          </div>
        </div>
      </template>
    </Dialog>
  </div>
</template>


<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons-vue'
import { getCurrentInstance } from 'vue'
const { ctx } = getCurrentInstance()

let router = useRouter()
let dialogVisible = ref(false)
let list = ref([])
let subjectId = ref('')
const handleClose = () => {
  dialogVisible.value = false
}
const toPaper = (item) => {
  handleClose()
  // let form = {
  //   subject_id: this.subjectId,
  //   knowledge_id: item.knowledge_id
  // }
  window.sessionStorage.removeItem('testpaperCardIndex')
  window.sessionStorage.removeItem('paper')
  router.push('/doPaper?isChapter=1&chapter_id=' + item.chapter_id)
}
const change = (index, bool) => {
  list.value[index].active = bool
  // this.$forceUpdate()
  ctx.$forceUpdate()
}
defineExpose({
  subjectId, list, dialogVisible
})
/*export default {
  data () {
    return {
      dialogVisible: false,
      list: [],
      subjectId: ''
    }
  },
  methods: {
    handleClose () {
      this.dialogVisible = false
    },
    toPaper (item) {
      this.handleClose()
      // let form = {
      //   subject_id: this.subjectId,
      //   knowledge_id: item.knowledge_id
      // }
      window.sessionStorage.removeItem('testpaperCardIndex')
      window.sessionStorage.removeItem('paper')
      this.$router.push('/testpaper/paperinfo?isChapter=1&chapter_id=' + item.chapter_id)
    },
    change (index, bool) {
      this.list[index].active = bool
      this.$forceUpdate()
    }
  }
}*/
</script>

<style lang="scss" scoped>
.table_head {
  width: 100%;
  height: 58rem;
  background: #f6f6f6;
  display: flex;
  align-items: center;
  div {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 24rem;
    color: #666660;
    &:nth-child(1) {
      // width: 497rem;
      flex: 5;
      text-indent: 148rem;
    }
    &:nth-child(2) {
      flex: 1;
      text-align: center;
      display: block;
    }
    &:nth-child(3) {
      flex: 2;
      text-align: center;
      display: block;
      text-indent: 33px;
    }
  }
}
.table_line {
  .ant-btn {
    font-size: 22rem;
    height: 44rem;
    border-radius: 5rem;
    padding: 0rem 16rem;
  }
  background: white;
  border-bottom: 1px solid #eaeaea;

  line-height: 68rem;
  .two {
    display: flex;
    align-items: center;
    margin: 5px 0;
    position: relative;
    font-size: 24rem;
    div {
      &:nth-child(1) {
        flex: 4;
        // width: 497rem;
        text-indent: 87rem;
        font-weight: bold;
      }
      &:nth-child(2) {
        flex: 1;
        text-indent: 15px;
        font-weight: bold;
        color: #ff6147;
        font-size: 14px;
      }
      &:nth-child(3) {
        flex: 1;
      }
    }
  }
  .three {
    display: flex;
    align-items: center;
    background: #f5faff;
    font-size: 24rem;
    div {
      &:nth-child(1) {
        flex: 4;
        text-indent: 87rem;
        color: #333333;
      }
      // &:nth-child(2) {
      //   flex: 1;
      //   text-indent: 15px;
      //   font-weight: bold;
      //   color: #ff6147;
      //   font-size: 14px;
      // }
      &:nth-child(2) {
        flex: 1;
      }
      &:nth-child(3) {
        flex: 1;
      }
    }
  }
}

.icon {
  text-indent: 0;
  width: 28rem;
  height: 28rem;
  border-radius: 50%;
  position: absolute;
  font-size: 18rem;
  font-weight: 700;
  margin-right: 30rem;
  margin-left: 40rem;
  display: flex;
  justify-content: center;
  align-items: center;
  // background: #2196f3;
  color: white !important;
}
</style>