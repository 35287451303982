import { instance } from '@/utils/http.js'

// 获取章节
/**
 * subject_id,level,keyword,reate
 */
export const getChapterByParams = function (params) {
  return instance({
    url: '/api/v1/chapter/list',
    method: 'get',
    params
  })
}