<template>
  <div class="cuotiben_Warp">
    <CommonTopSearch ref="CommonTopSearchRef"
                     url="/api/v1/public/subjects"
                     @initData="getChapterOne"
                     :isDefault='false' />

    <div class="select_subject"
         v-show="chapterOneList&&chapterOneList.length>0">
      <!-- 下拉选择框 -->
      <dropDown :list='chapterOneList'
                :index='subjectIndex'
                attribute_name='chapter_desc'
                @changeMenu='checkOneChapter'
                background='#1372e9' />
    </div>
    <div class="topic_warp">
      <div class="topic"
           v-for="(item,index) in chapterTwoList"
           :key="index">
        <div class="topic_card">
          <div class="top_text">
            {{item.chapter_desc}}
          </div>
          <div class="center">
            <div>
              <!-- 得分率:<span>{{item.rate}}</span> -->
            </div>
            <div>
              <span>
                频率:
              </span>
              <img src="@/static/zhishidian/xx.jpg"
                   v-for="(item,idx) in item.chapter_star"
                   :key="idx" />
            </div>
          </div>
          <div class="btn">
            <div @click="showOther(item)">
              二级条目
            </div>
            <span>
              |
            </span>
            <div @click="toDopaper(item)">
              进入训练
            </div>
          </div>
        </div>
      </div>
      <!-- 占位 -->
      <div class="topic1"></div>
      <div class="topic1"></div>
      <div class="topic1"></div>
      <!-- 占位 -->
    </div>
    <OtherForm ref="OtherFormRef" />
  </div>
</template>

<script setup>
import OtherForm from './components/otherChapter.vue'
import CommonTopSearch from '../components/COMMON_TOP_SEARCH/index.vue'
import { getChapterByParams } from '@/api/chapter.js'
import dropDown from '@/views/components/dropdown/index.vue'
import { message } from 'ant-design-vue'
import { useRoute, useRouter } from 'vue-router'
import { ref, watch } from 'vue'
let router = useRouter()
let chapterOneList = ref([])
let chapterTwoList = ref([])
let subjectIndex = ref(0)
let keywords = ref('')
let OtherFormRef = ref()

const getChapterOne = async (id, keyword) => {
  subjectIndex.value = 0
  chapterTwoList.value = []
  keywords.value = keyword
  // 切换科目的时候 keywords是会被清空的 keywords存在的话  查列表
  if (keyword) {
    getChapterTwo(chapterOneList.value[subjectIndex.value])
    return
  }
  let params = {
    subject_id: id,
    rate: 0
  }
  const { data } = await getChapterByParams(params)
  chapterOneList.value = data.list
  getChapterTwo(chapterOneList.value[subjectIndex.value])
}
const toDopaper = (item) => {
  window.sessionStorage.removeItem('testpaperCardIndex')
  window.sessionStorage.removeItem('paper')
  router.push('/doPaper?isChapter=1&chapter_id=' + item.chapter_id)
}
const showOther = async (item) => {
  let params = {
    chapter_parent_id: item.chapter_id,
    rate: 1
  }
  const { data } = await getChapterByParams(params)
  if (!data.list.length) {
    message.warning('没有二级条目')
    return
  }

  let list = data.list.map(item => {
    item.active = false
    return item
  })

  OtherFormRef.value.subjectId = item.subject_id
  OtherFormRef.value.list = list
  OtherFormRef.value.dialogVisible = true
}
const checkOneChapter = (index, item) => {
  subjectIndex.value = index
  getChapterTwo(item)
}
const getChapterTwo = async (item) => {
  let params = {
    rate: 1,
    chapter_parent_id: (item && item.chapter_id) || '',
    keyword: keywords.value
  }
  const { data } = await getChapterByParams(params)
  chapterTwoList.value = data.list
  //  .map(item => {
  //   item.knowledge_point_frequency = item.knowledge_point_frequency.toFixed(2)
  //   return item
}

</script>

<style lang="scss" scoped>
.cuotiben_Warp {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100vh;
  background-image: url("../../static/allBackg/bg章节训练.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: #205de8;

  .topic_warp {
    width: 100%;
    padding: 40rem 55rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow: hidden;
    overflow-y: auto;
    margin: 0 auto;
    max-height: calc(100vh - 160px);
    .topic1 {
      width: 430rem;
    }
    .topic {
      width: 430rem;
      height: 220rem;
      background-color: #b29bfa;
      border-radius: 20rem;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      margin-bottom: 20rem;
    }
    .topic_card {
      width: 410rem;
      height: 200rem;
      background: #fff;
      border-radius: 15rem;
      margin: 10rem auto;
      overflow: hidden;
      box-shadow: 0px 2px 8px 0px #622fd7;
      // border: 4rem solid #b29bfa;
      position: relative;

      .top_text {
        overflow: hidden;
        font-size: 22rem;
        font-weight: bold;
        color: #000000;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        margin: 10rem 18rem;
      }
      .center {
        flex: 1;
        font-size: 22rem;
        background: white;
        text-indent: 18rem;
        display: flex;
        div {
          &:nth-child(1) {
            font-weight: bold;
            color: #333333;
            font-size: 22rem;
            span {
              color: #ff6147;
            }
          }
          &:nth-child(2) {
            font-weight: bold;
            color: #999999;
            font-weight: 500;
            font-size: 22rem;
            display: flex;
            img {
              width: 20rem;
              height: 20rem;
              margin-left: 10rem;
              margin-top: 8rem;
            }
          }
        }
      }
      .btn {
        width: 410rem;
        height: 54rem;
        background: #b29bfa;
        box-shadow: 0px 2px 8px 0px #622fd7;
        border-radius: 0px 0px 15rem 15rem;
        position: absolute;
        bottom: 0;
        display: flex;
        align-items: center;
        span {
          color: white;
          font-size: 22rem;
        }
        div {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ffffff;
          font-size: 22rem;
          font-weight: bold;
          cursor: pointer;
        }
      }
    }
  }
}
.active {
  font-weight: bold;
  color: white !important;
}
.select_subject {
  position: absolute;
  top: 30rem;
  right: 40rem;
}
</style>
